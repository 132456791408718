<template>
    <v-form ref="form">
        <v-card class="card-shadow">
            <div class="card-title-wrapper pa-6">
                <v-row>
                    <v-col cols="12">
                        <v-card-title class="text-h1 font-weight-600 pa-0 mb-4">My Profile</v-card-title>
                        <v-card-subtitle class="text-subtitle-1 pa-0">Use this page to make changes to your profile. </v-card-subtitle>
                    </v-col>
                </v-row>
            </div>
            <v-card-text v-if="user" class="pa-6">
                <v-row>
                    <v-col class="image-flex" cols="12">
                        <Uploader class="image" @onRemove="saveUserOnImageRemoval" v-model="user.avatar" :accepts="['image/png', 'image/jpeg', 'image/jpg', 'image/webp']" :maxSize="2097152" :context="'avatar'" />
                        <v-row>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="xs-flex">
                                <label for="orgName" class="label-color font-weight-600 subtitle-2 mb-2 d-block">First Name</label>
                                <v-text-field :rules="[rules.required]" autocomplete="off" hide-details="auto" validate-on-blur id="orgName" v-model="user.first_name" outlined class="input-style font-size-input text-color-gray mt-0 mb-0" placeholder="John"> </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="xs-flex">
                                <label for="orgName" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Last Name</label>
                                <v-text-field :rules="[rules.required]" autocomplete="off" hide-details="auto" validate-on-blur id="orgName" v-model="user.last_name" outlined class="input-style font-size-input text-color-gray mt-0 mb-0" placeholder="Doe"> </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="xs-flex">
                                <label for="email" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Email</label>
                                <v-text-field :rules="[rules.required, rules.email]" autocomplete="off" hide-details="auto" validate-on-blur id="email" v-model="user.email" outlined class="input-style font-size-input text-color-gray mt-0 mb-0" placeholder="johndoe@gmail.com"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-btn @click="saveProfile()" elevation="0" height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm save-btn" color="#6F70FF">
                                    Update Profile
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { makeGetMixin } from 'feathers-vuex';
import Uploader from '@/components/UploaderV2/Uploader.vue';

export default {
    components: {
        Uploader
    },
    data() {
        return {
            userCopy: {},
            rules: {
                required: (value) => (value && Boolean(value)) || '*This field is required',
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                    return pattern.test(value) || 'Invalid e-mail.'
                }
            }
        };
    },
    mixins: [
        makeGetMixin({
            service: 'users',
            name: 'user',
            id: 'userId',
            queryWhen: 'userId',
            watch: true
        })
    ],
    methods: {
        async saveProfile() {
            if (this.$refs.form.validate()) {
                try {
                    await this.user.save();
                    this.showSuccess('Profile information successfully saved!');
                } catch (error) {
                    this.showError(error);
                }
            }
        },
        async saveUserOnImageRemoval() {
            if (this.$refs.form.validate()) {
                try {
                    await this.user.save();
                } catch (error) {
                    this.showError(error);
                }
            }
        },
        ...mapActions('app', ['showSuccess', 'showError']),
        ...mapActions('uploads', { removeUploads: 'remove' })
    },
    computed: {
        userId() {
            return this.authUser && this.authUser.id;
        },
        initials() {
            if (!this.user.first_name) return 'N';

            const initials = (this.user.first_name + ' ' + this.user.last_name).match(/\b\w/g) || [];

            return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    }
};
</script>

<style scoped lang="scss">
.image-upload {
    width: 175px !important;
    height: 175px !important;
}

.save-btn {
    width: 140px !important;
}

@media screen and (max-width: 599px) {
    .xs-flex {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 100%;
    }

    .image-flex {
        display: flex;
        flex-wrap: wrap;

        .image {
            display: block;
            margin: 0 auto;
            margin-bottom: 1rem;
            width: 150px;
        }
    }
}

@media screen and (min-width: 600px) {
    .image-flex {
        display: flex;
        flex-wrap: none;

        .image {
            margin-right: 1.6rem;
            width: 200px;
        }
    }
}
</style>
