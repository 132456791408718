<template>
    <v-card class="card-shadow">
        <div class="card-title-wrapper pa-6">
            <v-row>
                <v-col cols="8">
                    <v-card-title class="text-h1 font-weight-600 pa-0 mb-4">Overview</v-card-title>
                    <v-card-subtitle class="text-subtitle-1 pa-0">A general overview of the forms and submissions. </v-card-subtitle>
                </v-col>
            </v-row>
        </div>

        <v-card-text class="pa-6">
            <v-row>
                <v-col :xl="hasPermissions ? 3 : 4" sm="6" md="6" lg="3">
                    <outstanding-forms></outstanding-forms>
                </v-col>
                <v-col :xl="hasPermissions ? 3 : 4" sm="6" md="6" lg="3">
                    <in-progress-submissions></in-progress-submissions>
                </v-col>
                <v-col v-if="hasPermissions" sm="6" md="6" lg="3" xl="3">
                    <form-submissions></form-submissions>
                </v-col>
                <v-col :xl="hasPermissions ? 3 : 4" sm="6" md="6" lg="3">
                    <completed-forms></completed-forms>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="authUser && (authUser.role === 'Admin' || authUser.role === 'Manager' || authUser.role === 'GlobalManager')" sm="12" md="12" lg="6" xl="6">
                    <v-card class="card-shadow">
                        <div class="card-title-wrapper pa-8">
                            <v-row>
                                <v-col cols="12" class="d-flex justify-space-between align-center py-0">
                                    <v-card-title class="text-h2 font-weight-600 pa-0 mb-0 text-center">Form Analytics</v-card-title>
                                    <router-link to="/form-manager?tab=form-groups" class="text-decoration-none btn-hover no-default-hover">
                                        <v-btn elevation="0" height="40" class="font-weight-600 text-capitalize btn-primary rounded-xs mr-0" color="#6F70FF">
                                            <span class="text-white">View All</span>
                                        </v-btn>
                                    </router-link>
                                </v-col>
                            </v-row>
                        </div>
                        <form-groups></form-groups>
                    </v-card>
                </v-col>
                <v-col sm="12" md="12" lg="6" xl="6">
                    <v-card class="card-shadow">
                        <div class="card-title-wrapper pa-8">
                            <v-row>
                                <v-col cols="8" class="d-flex align-center py-0">
                                    <v-card-title class="text-h2 font-weight-600 pa-0 mb-0 text-center"
                                        >Activity Timeline&nbsp;<span v-if="authUser && (authUser.role === 'Admin' || authUser.role === 'GlobalManager')">(Global)</span></v-card-title
                                    >
                                </v-col>
                            </v-row>
                        </div>
                        <vue-perfect-scrollbar v-if="notifications.length" :settings="perfectScrollbarSettings" class="ps-customizer-area">
                            <v-timeline dense align-top class="timeline-line-color pr-8">
                                <v-timeline-item v-for="(notification, i) in notifications.slice(0, 10)" :key="i" small class="timeline pb-8">
                                    <template v-slot:icon>
                                        <v-avatar size="33" color="#F3F4FD">
                                            <feather type="bell" class="text-darker" stroke-width="1.5"></feather>
                                        </v-avatar>
                                    </template>

                                    <v-card>
                                        <v-card-title class="px-0 pt-1 pb-1">
                                            <span class="text-muted text-caption ls-0 font-weight-600">{{ createdAt(notification.createdAt) }}</span>
                                        </v-card-title>
                                        <v-card-text class="px-0">
                                            <h5 class="text-h4 text-typo font-weight-600 mt-1 mb-0">
                                                {{ notification.title }}
                                            </h5>
                                            <p class="mt-1 mb-0 text-body font-weight-thin">{{ notification.description }}</p>
                                        </v-card-text>
                                        <div class="avatar-with-name d-flex align-center">
                                            <v-avatar size="33" color="#F3F4FD">
                                                <span v-if="!retrieveUser(notification.user_id).avatar" class="text-primary text-h5 font-weight-600">{{
                                                    userInitials(retrieveUser(notification.user_id))
                                                }}</span>
                                                <img v-else :src="`${$feathersConnectionString}/${retrieveUser(notification.user_id).avatar.url}`" alt="User" />
                                            </v-avatar>
                                            <p class="text-h5 pa-0 ma-0 ml-3">
                                                {{ retrieveUser(notification.user_id).first_name }}
                                                {{ retrieveUser(notification.user_id).last_name }}
                                            </p>
                                        </div>
                                    </v-card>
                                </v-timeline-item>
                            </v-timeline>
                        </vue-perfect-scrollbar>
                        <v-card v-else>
                            <v-card-title class="ml-4">
                                <span class="text-muted text-caption ls-0 font-weight-600">You currently have 0 notifications.</span>
                            </v-card-title>
                        </v-card>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import OutstandingForms from '../components/OutstandingForms.vue';
import InProgressSubmissions from '../components/InProgressSubmissions.vue';
import FormSubmissions from '../components/FormSubmissions.vue';
import CompletedForms from '../components/CompletedForms.vue';
import FormGroups from '../components/FormGroups.vue';

export default {
    components: {
        VuePerfectScrollbar,
        OutstandingForms,
        InProgressSubmissions,
        FormSubmissions,
        CompletedForms,
        FormGroups
    },
    data() {
        return {
            perfectScrollbarSettings: {
                maxScrollbarLength: 200,
                wheelPropagation: false
            }
        };
    },
    mixins: [makeFindMixin({ service: 'notifications', watch: true }), makeFindMixin({ service: 'users', watch: true })],

    methods: {
        retrieveUser(userId) {
            const foundUser = this.users.find((user) => user.id === userId);

            if (foundUser === undefined) {
                return false;
            }

            return foundUser;
        },
        userInitials(user) {
            const initials = (user.first_name + ' ' + user.last_name).match(/\b\w/g) || [];

            return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        },
        createdAt(time) {
            var d = new Date(time);
            return this.$moment(d).calendar();
        }
    },
    computed: {
        notificationsParams() {
            if (!this.authUser?.role) return;

            if (this.authUser.role === 'Admin' || this.authUser.role === 'GlobalManager') {
                return {
                    query: {
                        $sort: {
                            createdAt: -1
                        }
                    }
                };
            }

            if (this.authUser.role === 'Manager') {
                return {
                    query: {
                        manager_id: this.authUser.id,
                        $sort: {
                            createdAt: -1
                        }
                    }
                };
            } else {
                return {
                    query: {
                        user_id: this.authUser.id,
                        $sort: {
                            createdAt: -1
                        }
                    }
                };
            }
        },
        usersParams() {
            return {
                query: {}
            };
        },
        hasPermissions() {
            if (!this.authUser?.role) return;

            if (this.authUser.role === 'Admin' || this.authUser.role === 'Manager' || this.authUser.role === 'GlobalManager') {
                return true;
            } else {
                return false;
            }
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    }
};
</script>
<style lang="scss" scoped>
.ps-customizer-area {
    max-height: 20.5rem;
}
</style>
