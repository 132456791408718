<template>
    <div>
        <div>
            <v-expansion-panel-header v-if="!isActive" hide-actions>
                <div class="d-flex flex-column">
                    <div class="font-weight-600 text-h1 mb-2">{{ sectionIndex }}. {{ section.title }}</div>
                    <div class="text-subtitle-2 text-light subtitle-2">{{ section.description }} </div>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-header v-else>
                <template v-slot:actions>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <feather type="more-vertical" class="text-darker" size="20" stroke-width="1.5"></feather>
                            </v-btn>
                        </template>

                        <v-list dense nav>
                            <v-list-item @click="removeSection()" link>
                                <v-list-item-content class="pa-0">
                                    <v-list-item-title class="text-darker">Remove Section</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    
                </template>
                <div class="d-flex flex-column">
                    <div class="font-weight-600 subtitle-2 pt-1">
                        <v-avatar color="#6F70FF" size="8" class="mr-2"></v-avatar>
                        Form Section
                    </div>
                    <div class="text-light subtitle-2">Fill in all your settings for the form section</div>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
                <v-row>
                    <v-col cols="12" class="py-0">
                        <label for="sectionTitle" class="label-color font-weight-600 subtitle-2 my-2 d-block">Section Title</label>
                        <v-text-field :rules="[rules.required]" autocomplete="off" hide-details="auto" id="sectionTitle" v-model="section.title" outlined class="input-style font-size-input text-color-gray mt-0 mb-6" placeholder="Section Title"> </v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <label for="sectionDescription" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Section Description</label>
                        <v-textarea id="sectionDescription" rows="3" v-model="section.description" hide-details="auto" outlined class="input-style font-size-input text-color-gray mt-0 mb-6" placeholder="Add a description..."> </v-textarea>
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        section: {
            type: Object,
            required: true
        },
        isActive: {
            type: Boolean,
            required: true
        },
        sectionIndex: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            rules: {
                required: (value) => (value && Boolean(value)) || '*This field is required'
            }
        };
    },
    methods: {
        removeSection() {
            this.$emit('remove')
        }
    }
};
</script>
<style scoped lang="scss">
.section-menu {
    position: absolute;
    right: -5.5rem;
    top: 50%;
    transform: translateY(-50%);
}

.v-menu__content {
    box-shadow: 0 0 1rem 0 rgb(136 152 170 / 15%) !important;
}

.add-btn {
    border-radius: 10rem !important;

    &:hover {
        cursor: pointer;
    }
}
</style>
