<template>
    <div>
        <div>
            <v-expansion-panel-header hide-actions v-if="!isActive">
                <div class="d-flex align-center">
                    <div class="mr-4">
                        <v-img class="drag-handle question-handle" :src="dragIcon" max-height="0.875rem"  contain />
                    </div>
                    <div>
                        <div class="font-weight-600 subtitle-2 pt-1">{{ questionIndex+1 }}. {{ question.name }}</div>
                        <div class="text-light subtitle-2">{{ question.type }}</div>
                    </div>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-header v-else>
                <div class="d-flex flex-column">
                    <div class="font-weight-600 subtitle-2 pt-1">
                        <v-avatar color="#6F70FF" size="8" class="mr-2"></v-avatar>
                        Question
                    </div>
                    <div class="text-light subtitle-2">Fill in all your settings for the question</div>
                </div>
                <template v-slot:actions>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click.stop="" @click="toggleGuide()" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="guide ? '#B7B8FF' : '#F3F4FD'">
                                    <feather type="info" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span>Add Guide</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click.stop="" @click="toggleQR()" class="ml-2" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="qrLabel ? '#B7B8FF' : '#F3F4FD'">
                                    <v-img  :src="qrIcon" max-height="15" contain />
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span>Add QR Code Label</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click.stop="" @click="toggleComments()" class="ml-2" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="comments ? '#B7B8FF' : '#F3F4FD'">
                                    <feather type="message-circle" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span>Add Comments</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click.stop="" @click="toggleEmailTo()" class="ml-2" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="emailTo ? '#B7B8FF' : '#F3F4FD'">
                                    <feather type="mail" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span>Email To</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click.stop="" @click="toggleImage()" class="ml-2" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" :color="image ? '#B7B8FF' : '#F3F4FD'" > 
                                    <feather type="image" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span>Add Image</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click.stop="" @click="remove()" class="ml-2" icon v-bind="attrs" v-on="on">
                                <v-avatar size="33" color="#F3F4FD">
                                    <feather type="delete" class="text-darker" size="20" stroke-width="1.5"></feather>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <span>Remove Question</span>
                    </v-tooltip>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager class="px-0 pb-0">
                <v-row class="mx-0 mb-2">
                    <v-col cols="6" class="pb-0">
                        <label for="questionName" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Question</label>
                        <v-text-field :rules="[rules.required]" autocomplete="off" hide-details="auto" id="questionName" v-model="question.name" outlined class="input-style font-size-input text-color-gray mt-0 mb-6" placeholder="What is your question?"> </v-text-field>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                        <label class="label-color font-weight-600 subtitle-2 mb-2 d-block">Question Type</label>
                        <v-select @change="resetQuestion()"  :items="questionTypes" v-model="question.type" item-text="type" item-value="type" single-line hide-details outlined class="input-style select-style text-color-gray" placeholder="Select type"> 
                        </v-select>
                    </v-col>
                    <v-col v-if="question.type === 'Formula'" cols="6" class="pt-0 pb-4">
                        <label class="label-color font-weight-600 subtitle-2 mb-2 d-block">Select Formula Type</label>
                        <v-select @change="changeFormulaType()" :items="formulaTypes" v-model="question.formulaType" single-line hide-details="auto" outlined class="input-style select-style text-color-gray" placeholder="Select type"> </v-select>
                    </v-col>
                    <v-col v-if="question.type === 'Formula'" cols="6" class="pt-0 pb-4">
                        <label class="label-color font-weight-600 subtitle-2 mb-2 d-block">Select Format</label>
                        <v-select :items="question.formulaType === 'List' ? textFormulaFormats : numformulaFormats" v-model="question.format" single-line hide-details="auto" outlined class="input-style select-style text-color-gray" placeholder="Select format"> </v-select>
                    </v-col>
                    <v-col
                        v-if="question.type === 'Dropdown' || question.type === 'Checkbox' || question.type === 'Single Choice' || question.type === 'Formula'" 
                        cols="12" 
                        class="pt-0 mb-4">
                        <label class="label-color font-weight-600 subtitle-2 mb-2 d-block">Options</label>
                            <v-row>
                                <draggable v-model="question.options" class="draggable-panel d-flex flex-wrap" handle=".option-handle">
                                    <v-col v-for="(option, index) in question.options" :key="index" cols="6" class="pb-0">
                                        <div class="d-flex align-center">
                                            <v-img class="drag-handle mr-1 option-handle" :src="dragIcon" max-height="0.875rem" max-width="1rem" contain />
                                            <v-text-field
                                                :rules="[rules.required]"
                                                autocomplete="off"
                                                hide-details="auto"
                                            
                                                v-model="option.value" 
                                                outlined 
                                                class="input-style font-size-input text-color-gray options-input" 
                                                :placeholder="`${index+1}. Label`"
                                            > 
                                                <template slot="append">
                                                    <v-menu offset-y>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn v-bind="attrs" v-on="on" v-if="question.type === 'Dropdown' || question.type === 'Checkbox' || question.type === 'Single Choice'" :ripple="false" class="ml-1" icon max-height="0.875rem">
                                                                <v-avatar size="28" :color="option.emailTo ? '#B7B8FF' : '#F3F4FD'">
                                                                    <feather type="mail" class="text-darker" size="18" stroke-width="1.5"></feather>
                                                                </v-avatar>
                                                            </v-btn>
                                                        </template>
                                                        <v-list>
                                                            <v-list-item @click="setAssignedUser(i, index, user.email)" v-for="(user, i) in users" :key="i" class="min-height-auto">
                                                                <v-list-item-avatar color="#F3F4FD" class="my-0 me-5 d-flex justify-center" width="37" min-width="15" height="37">
                                                                    <feather type="user" class="text-darker" size="20" stroke-width="1.5"></feather>
                                                                </v-list-item-avatar>
                                                                <v-list-item-content class="pa-0">
                                                                    <v-list-item-title class="text-darker">{{ user.email }}</v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                    <v-menu v-if="question.options.length > 1" offset-y>
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-btn :ripple="false" icon elevation="0" class="rounded-xs " color="#6F70FF" max-height="0.875rem" v-bind="attrs" v-on="on">
                                                            <feather type="more-vertical" class="text-darker" size="18" stroke-width="1.5"></feather>
                                                        </v-btn>
                                                        </template>
                                                        <v-list dense>
                                                            <v-list-item @click="removeOption(index)">
                                                                <v-list-item-title>Remove</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                </template>
                                            </v-text-field>
                                        </div>
                                    </v-col>
                                </draggable>
                                <v-col cols="6" class="pb-0 d-flex align-center justify-center" outlined>
                                    <v-btn @click="addOption()" icon>
                                        <v-avatar size="28" color="#F3F4FD">
                                            <feather type="plus" class="text-darker" size="18" stroke-width="1.5"></feather>
                                        </v-avatar>
                                    </v-btn>
                                    <v-btn v-if="question.options.length > 1" @click="removeOption()" icon>
                                        <v-avatar size="28" color="#F3F4FD">
                                            <feather type="minus" class="text-darker" size="18" stroke-width="1.5"></feather>
                                        </v-avatar>
                                    </v-btn>
                                </v-col>
                            </v-row>
                    </v-col>
                    <v-col v-if="guide" cols="6" class="py-0">
                        <label for="guide" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Guide</label>
                        <v-text-field :rules="[rules.required]" autocomplete="off" hide-details="auto" id="guide" v-model="question.guide" outlined class="input-style font-size-input text-color-gray mt-0 mb-6" placeholder="More info about the question."> </v-text-field>
                    </v-col>
                    <v-col v-if="qrLabel" cols="6" class="py-0">
                        <label class="label-color font-weight-600 subtitle-2 mb-2 d-block">QR and Label Options</label>
                        <v-select :items="qrLabelOptions" v-model="question.qrLabel" :rules="[rules.required]" autocomplete="off" hide-details="auto" single-line outlined class="input-style select-style text-color-gray mb-6" placeholder="Select option"> </v-select>
                    </v-col>
                    <v-col v-if="qrLabel && question.qrLabel.length" cols="6" class="py-0">
                        <label for="qrHeading" class="label-color font-weight-600 subtitle-2 mb-2 d-block">QR Heading</label>
                        <v-text-field id="qrHeading" v-model="question.qrHeading" autocomplete="off" hide-details="auto" outlined class="input-style font-size-input text-color-gray mt-0 mb-6" placeholder="Heading for QR Code"> </v-text-field>
                    </v-col>
                    <v-col v-if="comments" cols="6" class="py-0">
                        <label for="comments" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Additional Comments</label>
                        <v-textarea id="comments" rows="3" v-model="question.comments.label" outlined class="input-style font-size-input text-color-gray mt-0 mb-6" placeholder="Add any additional comments or notes."> </v-textarea>
                    </v-col>
                    <v-col v-if="emailTo" cols="6" class="py-0">
                        <label for="emailTo" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Email To</label>
                        <v-text-field :rules="[rules.required, rules.email]" autocomplete="off" validate-on-blur hide-details="auto" id="emailTo" v-model="question.emailTo" outlined class="input-style font-size-input text-color-gray mt-0 mb-6" placeholder="Fill in email..."> </v-text-field>
                    </v-col>
                    <v-col v-if="image" cols="2" class="py-0">
                        <Uploader @onRemove="saveFormOnImageRemoval" v-model="question.image" width="200px" :accepts="['image/png', 'image/jpeg', 'image/jpg', 'image/webp']" :maxSize="2097152" :context="'general'" />
                    </v-col>
                </v-row>
                <!-- <pre>{{ question }}</pre> -->
            </v-expansion-panel-content>
        </div>
    </div>
</template>

<script>
import Uploader from '@/components/UploaderV2/Uploader.vue';
import { mapGetters, mapActions } from "vuex";
import draggable from 'vuedraggable'
import { makeFindMixin } from 'feathers-vuex'

export default {
    components: {
        draggable,
        Uploader
    },
    props: {
        question: {
            type: Object,
            required: true
        },
        questionTypes: {
            type: Array,
            required: true
        },
        isActive: {
            type: Boolean,
            required: true
        },
        questionIndex: {
            type: Number,
            required: true
        }
    },
    mixins: [
        makeFindMixin({ service: 'users', watch: true })
    ],
    data() {
        return {
            qrIcon: require("@/assets/img/illustrations/qrcode-solid.svg"),
            dragIcon: require("@/assets/img/common/grip-vertical.svg"),
            qrLabelOptions: ['Print Label without QR code', 'Print Label and QR code', 'Print QR code without label'],
            formulaTypes: ['List', 'Sum', 'Average', 'Min', 'Max'],
            textFormulaFormats: ['Text', 'Number', 'Rand', 'US Dollar', 'Euro'],
            numformulaFormats: ['Number', 'Rand', 'US Dollar', 'Euro'],
            imageActive: false,
            guideActive: false,
            commentsActive: false,
            qrActive: false,
            emailToActive: false,
            fileUpload: null,
            isUploading: false,
            hasUploadingFailed: false,
            previewFileName: '',
            rules: {
                required: (value) => (value && Boolean(value)) || '*This field is required',
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                    return pattern.test(value) || 'Invalid e-mail.'
                }
            }
        };
    },
    methods: {
        toggleGuide() { this.guideActive = !this.guideActive; this.question.guide = '' },
        toggleComments() { this.commentsActive = !this.commentsActive; this.question.comments.label = '' },
        toggleQR() { this.qrActive = !this.qrActive; this.question.qrLabel = '', this.question.qrHeading = '' },
        toggleEmailTo() { this.emailToActive = !this.emailToActive; this.question.emailTo = '' },
        toggleImage() { this.imageActive = !this.imageActive; this.question.image = null; this.fileUpload = null },
        addOption() {
            const optionsLength = this.question.options.length

            const newOption = {
                value: `Option ${optionsLength+1}`,
                emailTo: ''
            }

            this.question.options.push(newOption);
        },
        removeOption(index) {
            if (index) {
                this.question.options.splice(index, 1)
            } else {
                this.question.options.pop()
            }
        },
        saveFormOnImageRemoval() {
            this.$emit("image");
        },
        duplicate() {
            this.$emit('duplicate')
        },
        remove() {
            this.$emit('remove')
        },
        resetQuestion() {
            this.$emit('reset')
        },
        changeFormulaType() {
            if (this.question.formulaType === 'List') {
                this.question.format = 'Text'
            } else {
                this.question.format = 'Number'
            }
        },
        setAssignedUser(userIndex, optionIndex, valueSelected) {
            if (this.question.options[optionIndex].emailTo === valueSelected) {
                this.question.options[optionIndex].emailTo = ""
            } else {
                this.question.options[optionIndex].emailTo = this.users[userIndex].email
            }
        },
        ...mapActions("app", ["showSuccess", "showError"])
    },
    computed: {
        guide: {
            get() {
                if (this.question.guide.length) {
                    // this.guideActive = true
                    return true
                } else if (this.guideActive) {
                    return true
                } else {
                    return false
                }
            },
            set() {
                this.toggleGuide()
            }
        },
        qrLabel: {
            get() {
                if (this.question.qrLabel.length) {
                    return true
                } else if (this.qrActive) {
                    // this.question.qrLabel = 'Print Label without QR code.'
                    return true
                } else {
                    return false
                }
            },
            set() {
                this.toggleQR()
            }
        },
        comments: {
            get() {
                if (this.question.comments.label.length) {
                    return true
                } else if (this.commentsActive) {
                    // this.question.comments.label = 'Add any additional comments or notes.'
                    return true
                } else {
                    return false
                }
            },
            set() {
                this.toggleComments()
            }
        },
        emailTo: {
            get() {
                if (this.question.emailTo && this.question.emailTo.length) {
                    return true
                } else if (this.emailToActive) {
                    return true
                } else {
                    return false
                }
            },
            set() {
                this.toggleComments()
            }
        },
        image: {
            get() {
                if (this.question.image) {
                    return true
                } else if (this.imageActive) {
                    return true
                } else {
                    return false
                }
            },
            set() {
                this.toggleImage()
            }
        },
        usersParams() {
            return {
                query: {
                    $sort: true,
                    role: {
                        $ne: 'Admin'
                    }
                }
            }
        },
        ...mapGetters("apiAuth", { authUser: "user" })
    }
};
</script>

<style scoped lang="scss">
.drag-handle {
    cursor: grab;
}

.draggable-panel {
  width: 100%;
}

.question-menu {
    position: absolute;
    right: -5.5rem;
    top: 50%;
    transform: translateY(-50%);
}

.v-menu__content {
    box-shadow: 0 0 1rem 0 rgb(136 152 170 / 15%) !important;
}

.add-btn {
    border-radius: 10rem !important;

    &:hover {
        cursor: pointer;
    }
}
</style>


