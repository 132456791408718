<template>
    <v-row v-if="formGroups" class="ma-1 mt-0">
        <v-col v-for="(group, index) in formGroups.slice(0, 2)" :key="index" cols="6">
            <v-card class="card-shadow pa-1 ma-2" solo>
                <v-card class="ma-2 mb-0 py-2" color="#F3F4FD">
                    <v-hover>
                        <template v-slot:default="{ hover }">
                            <v-card class="ma-2 mb-0 py-2" color="#F3F4FD">
                                <v-img :src="formIllustration" max-height="200" max-width="326" contain />
                                <v-fade-transition>
                                    <v-overlay v-if="hover" absolute opacity="0.1">
                                        <router-link :to="`/analytics/${group[1][0].form_id}`" class="text-decoration-none btn-hover no-default-hover">
                                            <v-btn elevation="0" height="40" class="font-weight-600 text-capitalize btn-primary rounded-xs mr-4" color="#6F70FF">
                                                <span class="text-white">View Analytics</span>
                                            </v-btn>
                                        </router-link>
                                    </v-overlay>
                                </v-fade-transition>
                            </v-card>
                        </template>
                    </v-hover>
                </v-card>
                <div class="d-flex justify-space-between align-center">
                    <div>
                        <v-card-title class="font-weight-600 subtitle-2 d-block pl-2">{{ group[0] }}</v-card-title>
                        <v-card-subtitle
                            class="font-weight-400 text-light d-flex align-center pt-2 pl-2"
                            v-if="(authUser.role === 'GlobalManager' || authUser.role === 'Admin') && group[1][0].manager_id !== authUser.id">
                            <feather type="user" class="text-darker mr-1" size="1.2rem" stroke-width="1.2"></feather>
                            <span>
                                Form created by&nbsp;<span class="font-weight-600">{{ retrieveUserWhoCreatedForm(group[1][0].manager_id) }}</span>
                            </span>
                        </v-card-subtitle>
                        <v-card-subtitle
                            class="font-weight-400 text-light d-flex align-center pt-2 pl-2"
                            v-if="(authUser.role === 'GlobalManager' || authUser.role === 'Admin') && group[1][0].manager_id === authUser.id">
                            <feather type="user" class="text-darker mr-1" size="1.2rem" stroke-width="1.2"></feather>
                            <span> Form created by you </span>
                        </v-card-subtitle>
                    </div>
                    <div class="font-weight-400 label text-light d-flex align-center mr-2">
                        <feather type="file-text" class="text-primary mr-1" size="1.2rem" stroke-width="1.2"></feather>
                        {{ group[1].length }}
                    </div>
                </div>
            </v-card>
        </v-col>
        <!-- <pre>{{ formGroups }}</pre> -->
    </v-row>
    <v-card v-else class="pb-2">
        <v-card-title class="ml-4">
            <span class="text-muted text-caption ls-0 font-weight-600">You currently have no form submissions.</span>
        </v-card-title>
    </v-card>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';

export default {
    mixins: [makeFindMixin({ service: 'submissions', watch: true }), makeFindMixin({ service: 'users', watch: true })],
    data() {
        return {
            formIllustration: require('@/assets/img/illustrations/undraw_pie_graph_x-9-dy.svg'),
            coverIllustration: require('@/assets/img/illustrations/undraw_Server_push_re_303w.svg')
        };
    },
    computed: {
        usersParams() {
            return {
                query: {
                    $sort: true
                }
            };
        },
        submissionsParams() {
            if (!this.authUser) return;

            let query = {};
            if (this.authUser.role === 'Admin' || this.authUser.role === 'GlobalManager') {
                query = {
                    status: 'Submitted'
                };
            } else {
                query = {
                    manager_id: this.authUser.id,
                    status: 'Submitted'
                };
            }

            query.$sort = {
                updatedAt: -1
            };

            return {
                query: query
            };
        },
        formGroups() {
            if (this.submissions.length) {
                const groups = this.submissions.reduce((acc, value) => {
                    if (!acc[value.name]) {
                        acc[value.name] = [];
                    }

                    acc[value.name].push(value);

                    return acc;
                }, {});

                const formGroups = Object.entries(groups).map((item) => {
                    return item;
                });

                return formGroups;
            }

            return null;
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    },
    methods: {
        retrieveUserWhoCreatedForm(id) {
            let user = this.retrieveUser(id);
            if (!user) return false;
            return `${user.first_name} ${user.last_name}`;
        },
        retrieveUser(id) {
            const foundUser = this.users.find((user) => user.id === id);

            if (foundUser === undefined) {
                return false;
            }

            return foundUser;
        }
    }
};
</script>
<style lang="scss" scoped>
.card-shadow-2 {
    box-shadow: 0 0 1rem 0 rgb(136 152 170 / 15%) !important;
}

.label {
    font-size: 0.8rem;
}
</style>



