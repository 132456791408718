<template>
    <v-card class="card-shadow">
        <v-hover>
            <template v-slot:default="{ hover }">
                <div class="d-flex align-center mb-2">
                    <v-card class="ma-4 mr-2 pa-2 d-flex justify-center" min-height="120" max-height="120" min-width="120" max-width="120" color="#F3F4FD">
                        <v-img :src="formIllustration" contain />
                    </v-card>
                    <div class="d-flex flex-column">
                        <v-card-title class="font-weight-600 subtitle-2 d-block pb-2 card-title">YOUR IN PROGRESS <br />FORMS</v-card-title>
                        <div v-if="inProgressSubmissionsLength > 0" class="form-number pl-4 card-subtitle">{{ inProgressSubmissionsLength }}</div>
                        <div v-else class="form-number pl-4">None</div>
                    </div>
                    <v-fade-transition>
                        <v-overlay v-if="hover" absolute opacity="0.1">
                            <router-link to="/my-forms?tab=in-progress-forms" class="text-decoration-none btn-hover no-default-hover">
                                <v-btn elevation="0" height="40" outlined class="font-weight-600 text-capitalize btn-primary rounded-xs mr-4" color="#6F70FF">
                                    <span class="text-primary">Go To In Progress Forms</span>
                                </v-btn>
                            </router-link>
                        </v-overlay>
                    </v-fade-transition>
                </div>
            </template>
        </v-hover>
    </v-card>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            formIllustration: require('@/assets/img/illustrations/undraw_Progress_overview_re_tvcl.svg')
        };
    },
    mixins: [makeFindMixin({ service: 'submissions', watch: true, fetchParams: 'submissionFetchParams' })],
    methods: {},
    computed: {
        submissionFetchParams() {
            if (!this.authUser) return;

            const query = {
                assigned: {
                    $in: [this.authUser.id]
                },
                status: {
                    $in: ['In Progress']
                },
                $limit: 9999999
            };

            return {
                query: query,
                paginate: false
            };
        },
        submissionsParams() {
            if (!this.authUser) return;

            const query = {
                assigned: {
                    $in: [this.authUser.id]
                },
                status: {
                    $in: ['In Progress']
                }
            };

            return {
                query: query
            };
        },
        inProgressSubmissionsLength() {
            if (this.submissions.length < 10) {
                return `0${this.submissions.length}`;
            }

            if (this.submissions.length > 999) {
                return `999+`;
            }

            return this.submissions.length;
        },
        ...mapGetters('apiAuth', { authUser: 'user' })
    }
};
</script>
<style lang="scss" scoped>
.form-number {
    color: black;
    font-weight: 600;
    font-size: 24px;
}

@media screen and (max-width: 1400px) {
    .card-title {
        font-size: 12px !important;
    }

    .card-subtitle {
        font-size: 16px !important;
    }
}
</style>
