<template>
    <div v-if="form && form.sections && !form.sections.length" class="text-center pa-3">
        <!-- <pre>{{ form }}</pre> -->
        <h1 class="text-h1 font-weight-600 text-black text-center mb-2 pt-5">Form is empty</h1>
        <p class="text-black font-size-root text-center font-weight-thin mb-2">You can start by creating your first section.</p>
        <v-img class="my-10 md-max-width" :src="illustration" max-height="300" contain />
        <v-btn @click="addSection()" elevation="0" height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm" color="#6F70FF">
            <feather type="plus" size="20" class="text-white mr-1" stroke-width="1.5"></feather>
            Add Section
        </v-btn>
    </div>
    <div v-else>
        <v-form ref="form" v-model="isFormValid" v-if="form && form.sections">
            <v-row v-for="(section, index) in form.sections" :key="index" class="d-flex justify-center">
                <v-col sm="10" md="10" lg="10" xl="8" v-show="index === currentPage - 1">
                    <v-expansion-panels v-model="sectionPanels" flat accordion>
                        <v-expansion-panel @click="setActiveSectionIndex(currentPage - 1, $event)" class="section-panel mb-6">
                            <section-type
                                :section="form.sections[currentPage - 1]"
                                @add-section="addSection()"
                                @add-question="addQuestion(currentPage - 1)"
                                @remove="removeSection(currentPage - 1)"
                                :isActive="checkSectionIndex(currentPage - 1)"
                                :sectionIndex="currentPage"></section-type>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-expansion-panels v-model="questionPanels" flat accordion>
                        <draggable v-model="form.sections[currentPage - 1].questions" class="draggable-panel" handle=".question-handle">
                            <v-expansion-panel @click="setActiveQuestionIndex(i, $event)" class="section-panel mb-6" v-for="(question, i) in form.sections[currentPage - 1].questions" :key="i">
                                <question-type
                                    @remove="removeQuestion(i)"
                                    @reset="resetQuestion(question)"
                                    @image="saveFormOnImageRemoval"
                                    :question="question"
                                    :questionTypes="questionTypes"
                                    :isActive="checkQuestionIndex(i)"
                                    :questionIndex="i">
                                </question-type>
                            </v-expansion-panel>
                        </draggable>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
                <v-col cols="8" class="py-0 mb-4">
                    <div class="d-flex align-center justify-space-between">
                        <div></div>
                        <div class="d-flex justify-center mb-4">
                            <v-menu :nudge-right="90" right :nudge-top="25">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="card-shadow add-btn" height="70" width="70" icon v-bind="attrs" v-on="on">
                                        <feather type="plus-circle" class="text-darker" size="40" stroke-width="0.75"></feather>
                                    </v-btn>
                                </template>

                                <v-list dense nav>
                                    <v-list dense nav>
                                        <v-list-item @click="addSection()" link>
                                            <v-list-item-avatar class="my-0 me-5" width="20" min-width="15" height="37">
                                                <feather type="grid" class="text-darker" size="1.2rem" stroke-width="1.5"></feather>
                                            </v-list-item-avatar>

                                            <v-list-item-content class="pa-0">
                                                <v-list-item-title class="text-darker">Add Section</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item @click="addQuestion(currentPage - 1)" link>
                                            <v-list-item-avatar class="my-0 me-5" width="20" min-width="15" height="37">
                                                <feather type="plus-circle" class="text-darker" size="1.2rem" stroke-width="1.5"></feather>
                                            </v-list-item-avatar>

                                            <v-list-item-content class="pa-0">
                                                <v-list-item-title class="text-darker">Add Question</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-list>
                            </v-menu>
                        </div>
                        <div class="d-flex justify-end align-center">
                            <v-pagination
                                prev-icon="fa fa-angle-left"
                                next-icon="fa fa-angle-right"
                                class="pagination"
                                color="#6F70FF"
                                v-model="currentPage"
                                :length="form.sections.length"
                                circle
                                @input="validateForm"></v-pagination>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import draggable from 'vuedraggable';
import SectionType from './components/Types/SectionType.vue';
import QuestionType from './components/Types/QuestionType.vue';

export default {
    components: {
        draggable,
        SectionType,
        QuestionType
    },
    props: {
        form: {
            type: Object,
            required: true
        },
        formValid: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            illustration: require('@/assets/img/illustrations/undraw_Add_post_re_174w.svg'),
            dragIcon: require('@/assets/img/common/grip-vertical.svg'),
            sectionPanels: [],
            activeQuestionIndex: -1,
            activeSectionIndex: -1,
            questionPanels: [],
            questionTypes: [
                { type: 'Text' },
                { type: 'Dropdown' },
                { type: 'Single Choice' },
                { type: 'Checkbox' },
                { type: 'Image' },
                { type: 'File' },
                { type: 'Date' },
                { type: 'Formula' },
                { type: 'Signature' }
            ],
            currentPage: 1
        };
    },
    methods: {
        validateForm() {
            this.questionPanels = [];
            this.activeQuestionIndex = -1;
            this.$refs.form.validate();
        },
        addSection() {
            const question = {
                name: 'Question',
                type: 'Text',
                options: [],
                formulaType: '',
                format: '',
                guide: '',
                qrLabel: '',
                qrHeading: '',
                image: null,
                emailTo: '',
                comments: {
                    label: '',
                    comment: ''
                }
            };

            const newSection = {
                title: 'New Section',
                description: '',
                questions: [question]
            };

            this.form.sections.push(newSection);

            if (this.form.sections.length > 1) {
                this.next();
            }
        },
        removeSection(index) {
            this.form.sections.splice(index, 1);
            if (this.currentPage - 1 !== 0) {
                this.prev();
            }

            this.sectionPanels = [];
            this.activeSectionIndex = -1;
        },
        addQuestion(sectionIndex) {
            const question = {
                name: 'Question',
                type: 'Text',
                options: [],
                formulaType: '',
                format: '',
                guide: '',
                qrLabel: '',
                qrHeading: '',
                image: null,
                emailTo: '',
                comments: {
                    label: '',
                    comment: ''
                }
            };

            this.form.sections[sectionIndex].questions.push(question);

            this.questionPanels = this.form.sections[sectionIndex].questions.length - 1;
            this.activeQuestionIndex = this.form.sections[sectionIndex].questions.length - 1;
        },
        removeQuestion(questionIndex) {
            this.form.sections[this.currentPage - 1].questions.splice(questionIndex, 1);

            this.questionPanels = this.form.sections[this.currentPage - 1].questions.length - 1;
            this.activeQuestionIndex = this.form.sections[this.currentPage - 1].questions.length - 1;
        },
        resetQuestion(question) {
            question.options = [];
            question.formulaType, question.format, question.printOption, question.guide, question.qrLabel, question.emailTo, (question.comments.label = '');
            question.image = null;

            if (question.type === 'Dropdown' || question.type === 'Single Choice' || question.type === 'Checkbox' || question.type === 'Formula') {
                const newOption = {
                    value: 'Option 1',
                    emailTo: ''
                };

                question.options.push(newOption);
            }

            if (question.type === 'Formula') {
                question.formulaType = 'List';
                question.format = 'Text';
            }
        },
        setActiveQuestionIndex(index, event) {
            if (event.currentTarget.classList.contains('v-expansion-panel-header--active')) {
                this.activeQuestionIndex = -1;
            } else {
                this.activeQuestionIndex = index;
            }
        },
        setActiveSectionIndex(index, event) {
            if (event.currentTarget.classList.contains('v-expansion-panel-header--active')) {
                this.activeSectionIndex = -1;
            } else {
                this.activeSectionIndex = index;
            }
        },
        checkQuestionIndex(index) {
            if (this.activeQuestionIndex === index) {
                return true;
            } else {
                return false;
            }
        },
        checkSectionIndex(index) {
            if (this.activeSectionIndex === index) {
                return true;
            } else {
                return false;
            }
        },
        async saveFormOnImageRemoval() {
            try {
                await this.form.save();
            } catch (error) {
                this.showError(error);
            }
        },
        prev() {
            this.currentPage--;
        },
        next() {
            this.currentPage++;
        },
        ...mapActions('app', ['showSuccess', 'showError'])
    },
    computed: {
        isFormValid: {
            get() {
                return this.formValid;
            },
            set(value) {
                this.$emit('update:formValid', value);
            }
        },
        sectionsLength() {
            if (this.form.sections && this.form.sections.length) {
                return this.form.sections.length;
            } else {
                return 1;
            }
        }
    }
};
</script>
<style scoped lang="scss">
.sections {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    align-items: center;
}

.drag-handle {
    cursor: grab;
}

.draggable-panel {
    width: 100%;
}

.active-panel {
    background-color: #f3f4fd !important;
}

.section-panel::before {
    box-shadow: 0 0 1rem 0 rgb(136 152 170 / 15%) !important;
}

.section-panel::after {
    border: none !important;
}

.v-menu__content {
    box-shadow: 0 0 1rem 0 rgb(136 152 170 / 15%) !important;
}

.add-btn {
    border-radius: 10rem !important;

    &:hover {
        cursor: pointer;
    }
}

@media screen and (max-width: 960px) {
    .md-max-width {
        width: 500px;
        max-width: 500px;
    }
}
</style>

